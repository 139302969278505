<!--
 * @Description: 客服工作量统计
 * @Author: ChenXueLin
 * @Date: 2022-03-10 09:50:34
 * @LastEditTime: 2022-07-15 16:03:17
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="employeeNo">
              <el-input
                v-model="searchForm.employeeNo"
                placeholder="工号"
                title="工号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userName">
              <el-input
                v-model="searchForm.userName"
                placeholder="用户名称"
                title="用户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="frameworkIds">
              <e6-select
                v-model="searchForm.frameworkIds"
                :dataList="selectData"
                placeholder="服务人组织机构"
                title="服务人组织机构"
                :filterable="true"
                :slotTemplate="'tree'"
                :multiple="false"
                :issingleleaf="false"
                ref="t_select"
                class="treeSelect"
              ></e6-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="serverTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.serverTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.serverTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          :span-method="arraySpanMethod"
        >
          <el-table-column
            label="工号"
            prop="employeeNo"
            width="160"
            header-align="center"
            align="center"
            fixed
          >
          </el-table-column>
          <el-table-column
            label="姓名"
            prop="userName"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="所属部门"
            prop="framework"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="服务类型"
            width="160"
            prop="third"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="服务类型"
            width="160"
            header-align="center"
            align="center"
          >
            <el-table-column
              label="服务类型"
              width="160"
              prop="fourth"
              header-align="center"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="option.className"
            header-align="center"
            align="center"
            v-for="(option, index) in optionsList"
            :key="index"
          >
            <el-table-column
              v-for="(item, index) in option.secondClass"
              :key="index"
              width="160"
              :label="item.className"
              align="center"
              :prop="item.classNameCode"
            ></el-table-column>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getFirstAndSecondClass,
  exportWorkload,
  getWorkload,
  getFrameworkTree
} from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
export default {
  name: "",
  components: {},
  data() {
    return {
      selectData: [],
      loading: false,
      optionsList: [],
      tableData: [],
      searchForm: {
        employeeNo: "",
        userName: "",
        frameworkIds: [],
        serverTime: [],
        startTime: "",
        endTime: "",
        pageSize: 10,
        pageIndex: 1
      },
      total: 0,
      needMergeArr: ["userName", "third", "framework", "employeeNo"], // 有合并项的列
      rowMergeArrs: {} // 包含需要一个或多个合并项信息的对象
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 服务时间
    "searchForm.serverTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.serverTime = this.searchForm.serverTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  methods: {
    async initData() {
      try {
        this.loading = true;
        let promiseList = [getFirstAndSecondClass(), getFrameworkTree()];
        let [optionsRes, frameworkRes] = await Promise.all(promiseList);
        //质检状态下拉框
        let optionsList = this.getFreezeResponse(optionsRes, {
          path: "data"
        });
        optionsList.map(item => {
          item.classNameCode = item.classNameCode
            ? item.classNameCode.toString()
            : "";
          item.secondClass.map(each => {
            each.classNameCode = each.classNameCode
              ? each.classNameCode.toString()
              : "";
          });
        });
        this.optionsList = optionsList;
        //部门下拉框
        this.handleTreeData(frameworkRes.data.children);
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    handleSearch() {
      this.initData();
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].personSum) {
          data[i]["label"] = data[i].name + "(" + data[i].personSum + ")";
        } else {
          data[i]["label"] = data[i].name;
        }
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.selectData = data;
    },
    //获取数据
    async queryList() {
      try {
        this.loading = true;
        let res = await getWorkload(this.searchForm);
        let arr = _.cloneDeep(res.data.array[0]);
        for (let i = 0; i < arr.length; i++) {
          if (
            i > 0 &&
            arr[i].userName == arr[i - 1].userName &&
            arr[i].employeeNo == arr[i - 1].employeeNo &&
            arr[i].framework == arr[i - 1].framework &&
            arr[i].thirdCode == arr[i - 1].thirdCode &&
            arr[i].fourthCode == arr[i - 1].fourthCode
          ) {
            arr[i - 1] = { ...arr[i - 1], ...arr[i] };
            arr.splice(i, 1);
          }
        }
        this.tableData = arr;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.rowMergeArrs = this.rowMergeHandle(
          this.needMergeArr,
          this.tableData
        );
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description 实现合并行或列
     * @param row:Object 需要合并的列name 如:'name' 'id'
     * @param column:Object 当前行的行数，由合并函数传入
     * @param rowIndex:Number 当前列的数据，由合并函数传入
     * @param columnIndex:Number 当前列的数据，由合并函数传入
     *
     * @return 函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，
     * 第二个元素代表colspan。 也可以返回一个键名为rowspan和colspan的对象
     */
    arraySpanMethod({ column, rowIndex }) {
      // row
      // 没办法循环判断具体是那一列 所以就只好写了多个if
      if (column.property === "userName")
        return this.mergeAction("userName", rowIndex, column);
      if (column.property === "third") {
        return this.mergeAction("third", rowIndex, column);
      }
      if (column.property === "employeeNo") {
        return this.mergeAction("employeeNo", rowIndex, column);
      }
      if (column.property === "framework") {
        return this.mergeAction("framework", rowIndex, column);
      }
    },
    /**
     * @description 根据数组来确定单元格是否需要合并
     * @param val:String 需要合并的列name 如:'name' 'id'
     * @param rowIndex:Number 当前行的行数，由合并函数传入
     * @param colData:Object 当前列的数据，由合并函数传入
     *
     * @return 返回值为一个数组表示该单元格是否需要合并;
     *  说明: [0,0]表示改行被合并了 [n+,1]n为1时表示该单元格不动,
     * n大于1时表示合并了N-1个单元格
     */
    mergeAction(val, rowIndex) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
    /**
     * @description 根据数组将指定对象转化为相应的数组
     * @param arr:Array[String] 必填 必须是字符串形式的数组
     * @param data:Array 必填 需要转化的对象
     *
     * @return 返回一个对象
     * 例：rowMerge(['name','value'],
     *  [{value:'1', name:'小明'},
     *  {value:'2', name:'小明'},
     * {value:'3', name:'小明'},
     * {value:'1', name:'小明'},
     * {value:'1', name:'小明'}])
     * 返回值: {
     *          name:{
     *            rowArr: [5, 0, 0, 0, 0]
     *            rowMergeNum: 0,
     *          },
     *          value: {
     *            rowArr: [1, 1, 1, 2, 0],
     *            rowMergeNum: 3
     *          }
     *        }
     */
    rowMergeHandle(arr, data) {
      if (!Array.isArray(arr) && !arr.length) return false;
      if (!Array.isArray(data) && !data.length) return false;
      let needMerge = {};
      arr.forEach(i => {
        needMerge[i] = {
          rowArr: [],
          rowMergeNum: 0
        };
        data.forEach((item, index) => {
          if (index === 0) {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = 0;
          } else {
            if (
              (item[i] === data[index - 1][i] &&
                (i === "userName" ||
                  i === "employeeNo" ||
                  i === "framework")) ||
              (item.a === data[index - 1].a &&
                item[i] === data[index - 1][i] &&
                i === "third")
            ) {
              needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
              needMerge[i].rowArr.push(0);
            } else {
              needMerge[i].rowArr.push(1);
              needMerge[i].rowMergeNum = index;
            }
          }
        });
      });
      return needMerge;
    },
    //导出
    handleExport() {
      exportData(this, exportWorkload);
    }
  },
  created() {
    this.initData();
    this.queryList();
  }
};
</script>
<style lang="scss" scoped></style>
